import { EnvEnum } from "@/src/enums/common";

export type FeatureToggleEnvType = Record<EnvEnum, boolean>;
export type FeatureToggleType = boolean | FeatureToggleEnvType;

export enum FeatureToggle {
  EnableApiDebug = "enable-api-debug",
  ServerLogger = "server-logger",
  EnableUiDebug = "enable-ui-debug",
  HtmlRichText = "html-rich-text",
  CustomExport = "custom-export",
  LocalizeProducts = "localize-products",
  EnrichmentSearchV2 = "enrichment-search-v2",
  BulkImageUpload = "bulk-image-upload",
}

export const featureToggleConfig: Record<FeatureToggle, FeatureToggleType> = {
  [FeatureToggle.EnableApiDebug]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: true,
    [EnvEnum.PRODUCTION]: false,
  },
  [FeatureToggle.ServerLogger]: {
    [EnvEnum.DEV]: false,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: true,
    [EnvEnum.PRODUCTION]: true,
  },
  [FeatureToggle.EnableUiDebug]: {
    [EnvEnum.DEV]: false,
    [EnvEnum.QA]: false,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
  [FeatureToggle.HtmlRichText]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: true,
    [EnvEnum.PRODUCTION]: true,
  },
  [FeatureToggle.CustomExport]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: false,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
  [FeatureToggle.LocalizeProducts]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: true,
    [EnvEnum.STAGING]: true,
    [EnvEnum.PRODUCTION]: true,
  },
  [FeatureToggle.EnrichmentSearchV2]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: false,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
  [FeatureToggle.BulkImageUpload]: {
    [EnvEnum.DEV]: true,
    [EnvEnum.QA]: false,
    [EnvEnum.STAGING]: false,
    [EnvEnum.PRODUCTION]: false,
  },
};
