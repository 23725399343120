import { CardSize } from "antd/es/card/Card";
import { theme } from "../theme";
import { Flex } from "antd";
import clsx from "clsx";

interface ActionCardGenericProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
  children: React.ReactNode;
}

interface ActionCardConfig {
  size?: CardSize;
  bordered?: boolean;
}

interface ActionCardTitleProps
  extends ActionCardGenericProps,
    ActionCardConfig {
  extra?: React.ReactNode;
}

interface ActionCardActionProps
  extends ActionCardGenericProps,
    ActionCardConfig {}

interface ActionCardProps extends ActionCardGenericProps, ActionCardConfig {
  title?: ActionCardTitleProps;
  actions?: ActionCardActionProps;
  bodyClassName?: string;
}

function ActionCardTitle({
  children,
  extra,
  size,
  className,
  style,
  ...rest
}: ActionCardTitleProps) {
  const {
    token: {
      padding,
      paddingXS,
      paddingSM,
      paddingLG,
      sizeXS,
      fontSizeSM,
      fontSize,
      fontWeightStrong,
      colorBorderSecondary,
      lineWidth,
      colorBgBase,
    },
  } = theme.useToken();

  return (
    <Flex
      gap={sizeXS}
      align="center"
      justify="space-between"
      style={{
        backgroundColor: colorBgBase,
        fontWeight: fontWeightStrong,
        borderBlockWidth: lineWidth,
        borderColor: colorBorderSecondary,
        fontSize: size === "small" ? fontSizeSM : fontSize,
        paddingBlock: size === "small" ? paddingXS : padding, // vertical
        paddingInline: size === "small" ? paddingSM : paddingLG, // horizontal
        borderTopLeftRadius: "inherit",
        borderTopRightRadius: "inherit",
        ...style,
      }}
      className={className}
    >
      <div {...rest}>{children}</div>
      {extra}
    </Flex>
  );
}

function ActionCardActions({
  children,
  style,
  size,
  ...rest
}: ActionCardActionProps) {
  const {
    token: { padding, paddingSM, paddingLG, colorBorderSecondary, lineWidth },
  } = theme.useToken();

  return (
    <div
      {...rest}
      style={{
        ...style,

        paddingInline: size === "small" ? padding : paddingLG, // horizontal
        paddingBlock: size === "small" ? paddingSM : padding, // vertical
        borderColor: colorBorderSecondary,
        borderTopWidth: lineWidth,
      }}
    >
      {children}
    </div>
  );
}

export function ActionCard({
  children,
  style,
  size = "default",
  bordered = true,
  actions: actionProps,
  title: titleProps,
  bodyClassName,
  ...rest
}: ActionCardProps) {
  const {
    token: {
      padding,
      paddingSM,
      paddingLG,
      lineWidth,
      borderRadiusLG,
      colorBorderSecondary,
    },
  } = theme.useToken();

  return (
    <div
      {...rest}
      style={{
        ...style,
        borderColor: colorBorderSecondary,
        borderInlineWidth: bordered ? lineWidth : undefined,
        borderBottomWidth: bordered ? lineWidth : undefined,
        borderTopWidth: bordered && !titleProps ? lineWidth : undefined,
        borderRadius: borderRadiusLG,
      }}
    >
      {titleProps ? <ActionCardTitle {...titleProps} size={size} /> : null}
      <div
        className={clsx("relative", bodyClassName)}
        style={{
          paddingBlock: size === "small" ? paddingSM : padding, // vertical
          paddingInline: size === "small" ? paddingSM : paddingLG, // horizontal
        }}
      >
        {children}
      </div>
      {actionProps ? <ActionCardActions {...actionProps} size={size} /> : null}
    </div>
  );
}
