import { Select as BaseSelect } from "antd";
import { selectSearchFilter } from "../utils/formatting";
import type { GetProps } from "antd";
import type { BaseOptionType, DefaultOptionType } from "antd/es/select";

type BaseSelectProps<
  T,
  K extends BaseOptionType | DefaultOptionType,
> = GetProps<typeof BaseSelect<T, K>>;

const Select = <
  T,
  K extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>(
  props: BaseSelectProps<T, K>
) => <BaseSelect showSearch filterOption={selectSearchFilter} {...props} />;

export { Select };
export type { DefaultOptionType };
export type { SelectProps } from "antd";
