import { Tag as DefaultTag, GetProps, Tooltip } from "antd";

export interface TagProps extends GetProps<typeof DefaultTag> {
  toolTip?: GetProps<typeof Tooltip>;
}

export const Tag = ({ toolTip, ...props }: TagProps) => {
  if (toolTip) {
    return (
      <Tooltip {...toolTip}>
        <DefaultTag {...props} />
      </Tooltip>
    );
  }

  return <DefaultTag {...props} />;
};
