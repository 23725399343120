"use client";

import {
  Popconfirm as DefaultPopconfirm,
  PopconfirmProps as DefaultPopconfirmProps,
} from "antd";
import { useState } from "react";

interface PopconfirmProps
  extends Omit<DefaultPopconfirmProps, "open" | "onOpenChange"> {
  skipConfirm?: boolean;
}

export function Popconfirm({
  skipConfirm = false,
  children,
  onConfirm,
  onCancel,
  ...props
}: PopconfirmProps) {
  const [open, setOpen] = useState(false);

  const confirm = () => {
    setOpen(false);
    onConfirm?.();
  };

  const cancel = () => {
    setOpen(false);
    onCancel?.();
  };

  const handleOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      setOpen(newOpen);
      return;
    }
    if (skipConfirm) {
      confirm(); // next step
    } else {
      setOpen(newOpen);
    }
  };

  return (
    <DefaultPopconfirm
      {...props}
      open={open}
      onOpenChange={handleOpenChange}
      onConfirm={confirm}
      onCancel={cancel}
    >
      {children}
    </DefaultPopconfirm>
  );
}
