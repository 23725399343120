"use client";

import { useTranslations } from "next-intl";
import { Namespace } from "@/src/i18n/namespace";
import { Button } from "../Button";

import "./style.css";
import { useEffect, useRef, useState } from "react";

interface CollapsibleContainerProps {
  isCollapsed: boolean;
  maxHeight?: number;
  setIsCollapsed: (value: boolean) => void;
  children: React.ReactNode;
}

const DEFAULT_MAX_HEIGHT = 440;

export function CollapsibleContainer({
  isCollapsed,
  maxHeight = DEFAULT_MAX_HEIGHT,
  setIsCollapsed,
  children,
}: CollapsibleContainerProps) {
  const tCommon = useTranslations(Namespace.COMMON);
  const contentRef = useRef<HTMLDivElement>(null);
  const [hasContentOverflow, setHasContentOverflow] = useState(false);

  useEffect(() => {
    setHasContentOverflow((contentRef.current?.offsetHeight || 0) >= maxHeight);
  }, [children]);

  return (
    <div className="collapsible-container">
      <div
        ref={contentRef}
        className="collapsible-container-content"
        style={{ maxHeight: isCollapsed ? maxHeight : undefined }}
      >
        {children}
      </div>
      {isCollapsed && !!hasContentOverflow && (
        <div className="collapsible-container-action">
          <Button
            type="text"
            size="small"
            className="collapsible-container-button"
            onClick={(e) => {
              e.stopPropagation();
              setIsCollapsed(false);
            }}
          >
            {tCommon("shared.showMore")}
          </Button>
        </div>
      )}
    </div>
  );
}
