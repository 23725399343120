"use client";

import React, { createContext, Dispatch } from "react";
import { AppContextType } from "./types";

interface AppContextProps {
  ctx: AppContextType;
  setContext: Dispatch<React.SetStateAction<AppContextType>>;
}

export const AppContext = createContext<AppContextProps | undefined>(undefined);
