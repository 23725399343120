import clsx from "clsx";

interface PulseProps {
  width?: number;
  height?: number;
  color: string;
  hidden?: boolean;
  active?: boolean; // show pulse animation
  className?: string;
}

export function Pulse({
  width = 6,
  height = 6,
  color,
  hidden = false,
  active,
  className,
}: PulseProps) {
  return (
    <span
      style={{
        width,
        height,
        display: hidden ? "none" : "flex",
      }}
      className={clsx(className)}
    >
      {active ? (
        <span
          style={{
            backgroundColor: color,
          }}
          className="animate-ping absolute inline-flex h-full w-full rounded-full opacity-50"
        />
      ) : null}
      <span
        style={{
          width,
          height,
          backgroundColor: color,
        }}
        className="relative inline-flex rounded-full"
      />
    </span>
  );
}
