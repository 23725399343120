import { Modal as BaseModal, ModalProps } from "antd";

const Modal = (props: ModalProps) => (
  <BaseModal {...props} maskClosable centered />
);

Modal.confirm = BaseModal.confirm;
Modal.useModal = BaseModal.useModal;
Modal.warning = BaseModal.warning;

export { Modal };
