import useAppContext from "@/src/components/appContext/useAppContext";
import { FeatureToggle } from "@/src/constants/featureFlags";

export function useFeatureFlag() {
  const { ctx } = useAppContext();

  const isEnabled = (key: FeatureToggle) =>
    ctx?.featureFlags ? ctx.featureFlags[key] : false;

  return {
    isApiDebugEnabled: isEnabled(FeatureToggle.EnableApiDebug),
    isUIDebugEnabled: isEnabled(FeatureToggle.EnableUiDebug),
    isHtmlRichTextEnabled: isEnabled(FeatureToggle.HtmlRichText),
    isCustomExportEnabled: isEnabled(FeatureToggle.CustomExport),
    isLocalizeProductsEnabled: isEnabled(FeatureToggle.LocalizeProducts),
    isEnrichmentV2SearchEnabled: isEnabled(FeatureToggle.EnrichmentSearchV2),
    isBulkImageUploadEnabled: isEnabled(FeatureToggle.BulkImageUpload),
  };
}
