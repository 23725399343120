import { InfoCircleOutlined } from "@ant-design/icons";
import { GetProps, Tooltip } from "antd";
import { Text } from "./Typography";

interface TooltipIconProps extends Omit<GetProps<typeof Tooltip>, "children"> {
  icon?: React.ReactNode;
  iconClassName?: string;
}

function TooltipIcon({
  icon,
  iconClassName,
  ...toolTipProps
}: TooltipIconProps) {
  return (
    <Tooltip {...toolTipProps}>
      <Text className={iconClassName} type="secondary">
        {icon ?? <InfoCircleOutlined />}
      </Text>
    </Tooltip>
  );
}
export { Tooltip, TooltipIcon };
