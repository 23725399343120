import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CSSProperties } from "react";

const Spinner = ({
  className,
  style,
}: {
  className?: string;
  style?: CSSProperties;
}) => (
  <Spin
    indicator={<LoadingOutlined style={{ fontSize: 24, ...style }} spin />}
    className={className}
  />
);

export { Spin, Spinner };
